/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages 

        // function setCookie(cname, cvalue, exdays) {
        //   var d = new Date();
        //   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        //   var expires = "expires="+d.toUTCString();
        //   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        // }

        // function getCookie(cname) {
        //   var name = cname + "=";
        //   var ca = document.cookie.split(';');
        //   for(var i = 0; i < ca.length; i++) {
        //     var c = ca[i];
        //     while (c.charAt(0) == ' ') {
        //       c = c.substring(1);
        //     }
        //     if (c.indexOf(name) == 0) {
        //       return c.substring(name.length, c.length);
        //     }
        //   }
        //   return "";
        // }

        // this.socialPopup();

        var player = new Plyr('#trmp-vimeo-player');
        player.decreaseVolume(100);
        var lightbox = false;

        player.on('ready', function () { 
          lightbox = lity("#popup-video-secion");           
        });

        player.on('playing', function () { 
          player.increaseVolume(80);
        });


        $(document).on('lity:ready',function(){
          $('button[data-plyr="play"]').click();
          player.decreaseVolume(100);
          player.play(); 
          // setTimeout(function(){
          //   player.increaseVolume(80);
          // },1000);
        });

        player.on("ended", function(event) {
          console.log(lightbox);
          lightbox.close();
          player.decreaseVolume(100);
        });




      },
      socialPopup: function() {
        // Create browser compatible event handler.
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        // Listen for a message from the iframe.
        eventer(messageEvent, function(e) {
          if (isNaN(e.data)) { return; }

          // replace #sizetracker with what ever what ever iframe id you need
          //jQuery('iframe')[0].style.height = e.data + 'px';
        }, false);

        function popupwindow(uri, name, w, h) {
          var y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
          var x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

          var win = window.open(uri, name, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x );
          var interval = window.setInterval(function() {
              try {
                  if (win == null || win.closed) {
                    window.clearInterval(interval);
                  }
              }
              catch (e) {
              }
          }, 1000);
          return win;
        }

        $(document).on('click','.social-links .list-inline a', function(e){
          if ( ! $(this).closest('li').hasClass('mail-icon') ) {
            e.preventDefault();
            var width = 600;
            var height = 500;
            popupwindow( $(this).attr('href'), $(this).attr('title'), width, height );
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
